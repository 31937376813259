import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily : [
            "Anuphan",
        ].join(","),
    },
    palette: {
        primary: {
            main: '#028858',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;